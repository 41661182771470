const initialState = {
    wingData: [],
    siteDetailData: {}
}

const wingDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'WING_DATA': {
            return {
                ...state,
                wingData: [...action.payload]
            }
        }
        case 'SITE_DETAIL_DATA': {
            return {
                ...state,
                siteDetailData: {
                    ...state.siteDetailData,
                    ...action.payload
                }
            }
        }
        default:
            return state
    }
}

export default wingDataReducer