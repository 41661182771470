// ** Logo
import logo from '@src/assets/images/logo/LOGO-whiteGroup.svg'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner position-absolute h-100 w-100'>
      <div className='contain position-fixed' style={{ zIndex: "9999"}}>
        <div className='loading'>
          <div className='effect-1 effects'></div>
          <div className='effect-2 effects'></div>
          <div className='effect-3 effects'></div>
        </div>
      </div>
    </div>
  )
}

export default SpinnerComponent
