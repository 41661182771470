
const initialState = {
    userData: {}
  }

const loaderReducer = (state = initialState, action) => {
  
    switch (action.type) {        
       case "LOADER_DATA" : 
       return {
         ...state,
        loader : action.data
       }
       default: 
       return state 
    }
}

export default loaderReducer