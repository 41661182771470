// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import wingDetail from './wingDetail'
import loaderReducer from './loader'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  wingDetail,
  loaderReducer
})

export default rootReducer
